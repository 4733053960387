<template>
    <div class="page-annual-report-2024" :class="{ mini: appTypeStr == 'mini' }">
        <HeadeBar left-arrow @click-left="newAppBack" :placeholder="false" onlyBack class="rback">
        </HeadeBar>
        <img class="music"
            :src="'https://img.chaolu.com.cn/ACT/annual-report-2024/' + (stop ? 'music2' : 'music') + '.png'"
            @click="mc" />
        <div class="page1" v-if="step == 1">
            <!-- <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/home.jpg" class="home" /> -->
            <div class="p1-con">
                <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page1-bg.png" />
                <div class="p1-main">
                    <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page1-main.png" class="p1-main-pic" />
                    <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page1-sub.png" class="p1-main-sub"
                        @click="step++" />
                </div>

            </div>
        </div>
        <div class="page2" v-if="step == 2">
            <div class="p2-con">
                <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page2-bg.png" />
                <div class="p2-main">
                    <div class="smodel puhui" :class="{ active: intj[1] == 'E' }" @click="intj[1] = 'E'">抱团党，没有搭子缺少乐子
                    </div>
                    <div class="smodel puhui" :class="{ active: intj[1] == 'I' }" @click="intj[1] = 'I'">独行侠，我要自己偷偷卷
                    </div>
                    <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/next.png" class="p2-main-sub"
                        @click="next(1)" />
                </div>

            </div>
        </div>
        <div class="page3" v-if="step == 3">
            <div class="p3-con">
                <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page2-bg.png" />
                <div class="p3-main">
                    <div class="smodel puhui" :class="{ active: intj[2] == 'N' }" @click="intj[2] = 'N'">主动尝鲜的好奇派</div>
                    <div class="smodel puhui" :class="{ active: intj[2] == 'S' }" @click="intj[2] = 'S'">从一而终的保守派</div>
                    <div class="subline">
                        <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/prev.png" class="subline-sub"
                            @click="step--" />
                        <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/next.png" class="subline-sub"
                            @click="next(2)" />
                    </div>

                </div>

            </div>
        </div>
        <div class="page4" v-if="step == 4">
            <div class="p4-con">
                <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page2-bg.png" />
                <div class="p4-main">
                    <div class="smodel puhui" :class="{ active: intj[3] == 'T' }" @click="intj[3] = 'T'">计划精确的执行党</div>
                    <div class="smodel puhui" :class="{ active: intj[3] == 'F' }" @click="intj[3] = 'F'">随缘就好的佛系派</div>
                    <div class="subline">
                        <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/prev.png" class="subline-sub"
                            @click="step--" />
                        <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/next.png" class="subline-sub"
                            @click="next(3)" />
                    </div>

                </div>

            </div>
        </div>
        <div class="page5" v-if="step == 5">
            <div class="p5-con">
                <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page2-bg.png" />
                <div class="p5-main">
                    <div class="smodel puhui" :class="{ active: intj[4] == 'J' }" @click="intj[4] = 'J'">精准到摄入量的食谱专家
                    </div>
                    <div class="smodel puhui" :class="{ active: intj[4] == 'P' }" @click="intj[4] = 'P'">运动就是为了吃更多</div>
                    <div class="subline">
                        <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/prev.png" class="subline-sub"
                            @click="step--" />
                        <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/final.png" class="subline-sub"
                            @click="getColor(4)" />
                    </div>

                </div>

            </div>
        </div>
        <div class="page6" v-if="step == 6">
            <img :src="'https://img.chaolu.com.cn/ACT/annual-report-2024/color' + color + '.png'" />
            <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/submit.png" class="final-sub" @click="save" />
        </div>
        <template v-if="step == 7">
            <div style="display: none;">
                <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page8-bg.png" />
                <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page9-bg.png" />
                <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page10-bg.png" />
            </div>
            <swiper class="data-swiper" :options="swipeOption" ref="dataSwiper" v-if="allData.userInfo.comeFlag">
                <swiper-slide>
                    <div class="page-swiper">
                        <img class="pic-bg" src="https://img.chaolu.com.cn/ACT/annual-report-2024/page7-bg.png" />
                        <div class="info-block po1">
                            <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/flogo.png" class="logo" />

                            <div class="bl">
                                你在<span class="puhui">{{ reviewData.firstCheckInYear }}</span>年<span class="puhui">{{
                                    reviewData.firstCheckInMonth }}</span>月<span class="puhui">{{
                                        reviewData.firstCheckInDay }}</span>日进入超鹿运动
                            </div>
                            <div class="bl bmb">开启了健康生活的每一天</div>
                            <div class="bl">转眼我们已经相伴了<span class="puhui">{{ reviewData.firstMeetingBetweenDay
                                    }}</span>天</div>
                            <div class="bl">未来继续一鹿同行！</div>

                        </div>
                        <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/up.png" class="up" @click="snext" />
                    </div>
                </swiper-slide>

                <swiper-slide>
                    <div class="page-swiper">
                        <img class="pic-bg" src="https://img.chaolu.com.cn/ACT/annual-report-2024/page8-bg.png" />
                        <div class="info-block po2">
                            <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/flogo.png" class="logo" />
                            <template v-if="reviewData.totalFinishPerNum">
                                <div class="bl">
                                    你一共完成<span class="puhui">{{ reviewData.totalFinishPerNum }}</span>节私教课
                                </div>
                                <div class="bl bmb"><span class="puhui">{{ reviewData.mostAttendPerTeacherName
                                        }}</span>教练是你蜕变路上的最佳拍档</div>
                                <div class="bl">见证身材的蜕变，由汗水和坚持铺垫</div>
                            </template>
                            <template v-else>
                                <div class="bl">
                                    你还没有上过私教课
                                </div>
                                <div class="bl bmb">超鹿运动拥有<span class="puhui">1000+</span>专业私人教练</div>
                                <div class="bl">我们会持续努力，希望未来遇到运动困扰的你</div>
                                <div class="bl">可以第一时间让ta们帮你</div>
                            </template>
                        </div>
                        <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/up.png" class="up" @click="snext" />
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="page-swiper">
                        <img class="pic-bg" src="https://img.chaolu.com.cn/ACT/annual-report-2024/page9-bg.png" />
                        <div class="info-block po3" :class="{ 'po3-2': !reviewData.totalFinishScheduleNum }">
                            <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/flogo.png" class="logo" />
                            <template v-if="reviewData.totalFinishScheduleNum">
                                <div class="bl">
                                    今年你一共上了<span class="puhui">{{ reviewData.totalFinishScheduleNum }}</span>节团课，累计消耗
                                    <span class="puhui"> {{ reviewData.totalBurnCalories }} </span>热量
                                </div>
                                <div class="bl">最多的一天，你上了<span class="puhui">{{ reviewData.maxFinishScheduleDayNum
                                        }}</span>节课</div>
                                <div class="bl">运动一定为你带来满满的内啡肽，</div>
                                <div class="bl bmb">让你感受愉悦活力！</div>
                                <div class="bl"><span class="puhui">{{ reviewData.mostAttendScheduleTeacherName
                                        }}</span>教练一定带给你最好的运动体验</div>
                                <div class="bl">2024年你一共约了<span class="puhui">{{ reviewData.mostAttendScheduleTeacherNum
                                        }}</span>节ta的课</div>
                            </template>
                            <template v-else>
                                <div class="bl">
                                    什么？你还没上过超鹿团课
                                </div>
                                <div class="bl">快来试试吧！</div>
                                <div class="bl">总有一节课可以让你收获运动快乐</div>
                            </template>
                        </div>
                        <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/up.png" class="up" @click="snext" />
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="page-swiper">
                        <img class="pic-bg" src="https://img.chaolu.com.cn/ACT/annual-report-2024/page10-bg.png" />
                        <div class="info-block po4">
                            <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/flogo.png" class="logo" />
                            <template v-if="reviewData.totalFinishMicroNum">
                                <div class="bl">
                                    你一共完成了<span class="puhui">{{ reviewData.totalFinishMicroNum }}</span>节小班课
                                </div>
                                <div class="bl bmb"><span class="puhui">{{ reviewData.mostAttendMicroName
                                        }}</span>课程是你的最爱
                                </div>
                                <div class="bl">恭喜你在过去的一年</div>
                                <div class="bl">成功解锁了新的课程体验</div>
                            </template>
                            <template v-else>
                                <div class="bl">
                                    可以约饭，也可以约汗
                                </div>
                                <div class="bl">一群人一起流汗一起动的快乐</div>
                                <div class="bl">就在超鹿小班课</div>
                            </template>
                        </div>
                        <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/submit.png" class="create"
                            @click="end" />
                    </div>
                </swiper-slide>
            </swiper>
            <div class="page7" v-else>
                <img class="pic-bg" src="https://img.chaolu.com.cn/ACT/annual-report-2024/page7-bg2.png" />
                <div class="info-block po1">
                    <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/flogo.png" class="logo" />
                    <div class="bl">
                        这一年，我们还未在场馆见过你的身影
                    </div>
                    <div class="bl">每一次相遇都是生活给予的惊喜</div>
                    <div class="bl">2025年</div>
                    <div class="bl">期待与你创造更多的专属运动回忆</div>
                </div>
                <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/sub-get2.png" class="nget" @click="nget" />
            </div>
        </template>
        <div class="page8" v-if="step == 8">
            <img :src="'https://img.chaolu.com.cn/ACT/annual-report-2024/' + (label + '' + color) + '.png'" />
            <div class="page8-top">
                <div class="top1">
                    <div class="userhead"><img :src="userDetail.headImg" /></div>
                    <div class="fh">@</div>
                    <div class="username">{{ userDetail.nickName }}</div>
                </div>
                <div class="top2">2024年，你的运动人格是</div>
            </div>
            <div class="page8-bottom">
                <div class="bottom1">
                    <div class="bm" :style="{ background: getBG() }">累计在馆时长<span class="bv puhui">{{
                        reviewData.totalCheckInMinutes
                            }}</span>分钟</div>
                    <div class="bm" :style="{ background: getBG() }">累计消耗<span class="bv puhui">{{
                        reviewData.totalSportCalories
                            }}</span>kcal</div>
                </div>
                <div class="bottom2">你的努力被我们典藏，来年继续乘风破浪！</div>
                <div class="bottom3">
                    <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/sub-poster.png" class="bsub bl"
                        @click="isShareShow = true" />
                    <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/sub-get.png" class="bsub br"
                        @click="$router.push('/invite-gift')" />
                </div>
            </div>
        </div>
        <!-- <div style="display: none;">
            <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page1-bg.png" />
            <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page2-bg.png" />
            <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page2-main.png" />
            <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page3-main.png" />
            <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page4-main.png" />
            <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page5-main.png" />
            <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/color1.png" />
            <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/color2.png" />
            <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/color3.png" />
            <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/color4.png" />
            <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page7-bg.png" />
            <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/page7-bg2.png" />
        </div> -->
        <common-share :value="isShareShow" :shareParams="shareParams" @close="isShareShow = false"
            @share-success="isShareShow = false" :shareItem="['posterfriend', 'postermessage', 'save']"
            :miniSharePoster="appTypeStr === 'mini'" :scale="1">
            <template slot="createDom">
                <div class="canvascss">
                    <img :src="'https://img.chaolu.com.cn/ACT/annual-report-2024/' + (label + '' + color) + '.png'" />
                    <img src="https://img.chaolu.com.cn/ACT/annual-report-2024/logo.png" class="clogo" />
                    <div class="page8-top">
                        <div class="top1">
                            <div class="userhead">
                                <img :src="userDetail.headImg" />
                            </div>
                            <div class="fh">@</div>
                            <div class="username">{{ userDetail.nickName }}</div>
                        </div>
                        <div class="top2">2024年，你的运动人格是</div>
                    </div>
                    <div class="page8-bottom">
                        <div class="bottom1">
                            <div class="bm" :style="{ background: getBG() }">累计在馆时长<span class="bv puhui">{{
                                reviewData.totalCheckInMinutes }}</span>分钟</div>
                            <div class="bm" :style="{ background: getBG() }">累计消耗<span class="bv puhui">{{
                                reviewData.totalSportCalories }}</span>kcal</div>
                        </div>
                        <div class="bottom2">你的努力被我们典藏，来年继续乘风破浪！</div>
                        <div class="bottom4">
                            <img v-if="userDetail.qrCode" :src="`data:image/png;base64,${userDetail.qrCode}`"
                                class="ccode" />
                            <div class="bt1">扫码查收</div>
                            <div class="bt2">2024年度超鹿运动报告</div>
                        </div>
                    </div>
                </div>
            </template>
        </common-share>
    </div>
</template>
<script>
import { newAppBack, initBack, goApplet } from '@/lib/appMethod';
import HeadeBar from '@/components/app/headBar';
import userMixin from '@/mixin/userMixin';
import CommonShare from '@/components/commonShare/newShare'
import wx from 'weixin-js-sdk'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

const webPath = `${window.location.origin}/#/superdeer-activity/annual-report-2024/index`
const miniPath = `/pages/webView/index?webUrl=${encodeURIComponent(webPath)}`

export default {
    mixins: [userMixin],
    components: {
        HeadeBar,
        CommonShare,
        swiper,
        swiperSlide
    },
    data() {
        return {
            swipeOption: {
                direction: 'vertical',
                effect: 'fade',
                followFinger: false,
                speed: 800
            },
            color: undefined,
            label: undefined,
            intj: {
                1: undefined,
                2: undefined,
                3: undefined,
                4: undefined
            },
            recommendList: [],
            step: 0,
            userDetail: {},
            shareParams: {
                title: '超鹿年度报告 ',
                miniImage: 'https://img.chaolu.com.cn/ACT/double12-2024/mini.png',
                path: '',
                miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
                url: 'url',
                multiple: '0.95',
                userName: 'gh_7f5b57b6a76e',
            },
            isShareShow: false,
            allData: {
                userInfo: {}
            },
            stop: false
        }
    },
    computed: {
        reviewData() {
            return this.allData?.reviewData || {}
        }
    },
    beforeDestroy() {
        window.source.disconnect();
        window.source = null;
        window.context = null;
    },
    methods: {
        newAppBack,
        snext() {
            this.$refs.dataSwiper.swiper.slideNext();
        },
        nget() {
            if (this.allData.userInfo.newFlag) {
                goApplet(undefined, '/pages/subPages/experience/index?inviteUid=9808763&mtj_shuuid=1476bd28092e43d5830540a9414d7664', 'gh_7f5b57b6a76e')
            } else {
                this.$router.push('/invite-gift')
            }
        },
        end() {
            this.step++;
            this.$axios
                .post(`${this.baseURLApp}/user/data/2025/review/read`, {
                    token: this.token,
                    userId: this.userId,
                })
        },
        mc() {
            // if (this.audio.paused) {
            //     this.audio.play();
            //     this.stop = false;
            // } else {
            //     this.audio.pause();
            //     this.stop = true;
            // }
            if (!window.source) {
                this.music();
                this.stop = false;
                return;
            }
            if (this.stop) {
                window.source.connect(window.context.destination);
                this.stop = false;
            } else {console.log(666666666)
                window.source.disconnect();
                this.stop = true;
            }
        },
        music() {
            // var audio = document.createElement('audio');
            // audio.src = 'https://img.chaolu.com.cn/ACT/annual-report-2024/bg.mp3';
            // audio.autoplay = true;
            // audio.loop = true;
            // this.audio = audio;
            // // var duration;
            // // duration = audio.duration;//长度单位是秒

            // // window.setInterval(function () {
            // //     audio.play();//播放
            // // }, duration * 1000 + 1000);
            // document.body.appendChild(audio);
            // setTimeout(() => {
            //     if (this.audio.paused) {
            //         this.stop = true;
            //     } else {
            //         this.stop = false;
            //     }

            // }, 2000)
            var contextClass = window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext ||
                window.msAudioContext;
            try {
                window.context = new contextClass();
                var audioBuffer = null;

                function playSound() {
                    window.source = window.context.createBufferSource();
                    window.source.buffer = audioBuffer;
                    window.source.loop = true;
                    window.source.connect(window.context.destination);
                    window.source.start(0); //立即播放

                }

                function initSound(arrayBuffer) {
                    window.context.decodeAudioData(arrayBuffer, function (buffer) { //解码成功时的回调函数
                        audioBuffer = buffer;
                        playSound();
                    }, function (e) { //解码出错时的回调函数
                        console.log('404', e);
                    });
                }

                function loadAudioFile(url) {
                    var xhr = new XMLHttpRequest(); //通过XHR下载音频文件
                    xhr.open('GET', url, true);
                    xhr.responseType = 'arraybuffer';
                    xhr.onload = function (e) { //下载完成
                        initSound(this.response);
                    };
                    xhr.send();
                }
                //这里用来存储背景音乐的路径
                loadAudioFile('https://img.chaolu.com.cn/ACT/annual-report-2024/bg.mp3');
            } catch (e) {
                console.log('无法找到音乐！');
            }
        },
        next(v) {
            if (this.intj[v] === undefined) {
                this.$toast('请选择');
                return;
            }
            this.step++; console.log(this.intj)
        },
        getColor(value) {
            if (this.intj[value] === undefined) {
                this.$toast('请选择');
                return;
            }
            const v = Object.values(this.intj).join('');
            console.log(v)
            const json = {
                'ISTJ': 1,
                'ISFJ': 1,
                'ESTJ': 1,
                'ESFJ': 1,
                'INTJ': 2,
                'INTP': 2,
                'ENTJ': 2,
                'ENTP': 2,
                'INFJ': 3,
                'INFP': 3,
                'ENFJ': 3,
                'ENFP': 3,
                'ISTP': 4,
                'ISFP': 4,
                'ESTP': 4,
                'ESFP': 4
            }
            this.color = json[v];
            this.step++;
        },
        getBG() {
            const json = {
                1: '#1C59D7',
                2: '#744BD8',
                3: '#61952E',
                4: '#FA9300'
            }
            return json[this.color]
        },
        save() {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: 'spinner',
            })
            this.$axios
                .post(`${this.baseURLApp}/user/data/2025/review/saveMBTI`, {
                    token: this.token,
                    userId: this.userId,
                    mbti: Object.values(this.intj).join('')
                }).then(async res => {
                    await this.getInfo();
                    this.$toast.clear();
                }).catch(() => {
                    this.$toast.clear()
                })
        },
        getInfo() {
            return this.$axios
                .post(`${this.baseURLApp}/user/data/2025/review`, {
                    userId: this.userId,
                    token: this.token,
                }).then(async res => {
                    this.allData = res.data;
                    console.log(res.data)
                    let v = 1;
                    if (this.allData.mbti) {
                        this.color = this.allData.mbti.color;
                        v = 7;
                    }
                    if (this.allData.reviewData) {
                        const lt = {
                            1: 'FLAG',
                            2: 'IMNB',
                            3: 'GGGG',
                            4: 'FOOD',
                            5: 'HHHH'
                        }

                        this.label = lt[this.allData.reviewData.userLabel];
                        console.log(this.label, this.color);

                        const m = new Image();
                        m.src = 'https://img.chaolu.com.cn/ACT/annual-report-2024/' + (this.label + '' + this.color) + '.png'
                    }
                    // if (this.allData.read) {
                    //     v = 8;
                    // }
                    this.step = v;

                })
        },
        initQr() {
            this.$axios
                .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
                    token: this.token,
                    userId: this.userId,
                    width: 240,
                    height: 240,
                    webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
                    scene: JSON.stringify({
                        source: 'ar2024',
                        webUrl: webPath,
                    }),
                })
                .then((res) => {
                    this.userDetail = res.data
                })
        },
    },
    mounted() {
    },
    async created() {
        initBack();
        await this.$getAllInfo(['userId', 'cityId', 'venueId', 'local']);
        console.log(this.userId, this.token)
        var u = navigator.userAgent;

        if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent)) {
            this.stop = true
        } else {
            this.music();
        }

        this.getInfo().then(res => {

        });
        this.initQr();
        // wx.miniProgram.postMessage({
        //     data: {
        //         type: 'share',
        //         shareImage: this.shareParams.miniImage,
        //         shareTitle: this.shareParams.title,
        //         shareUrl: this.shareParams.path,
        //     },
        // })
    }
}
</script>
<style lang="less" scoped>
.page-annual-report-2024 {
    &.mini {
        .info-block {
            margin-top: -88px;
        }

        .music {
            top: 112px;
        }
    }

    div {
        box-sizing: border-box;
    }

    img {
        width: 100%;
        vertical-align: top;
    }

    .music {
        width: 60px;
        height: 60px;
        position: fixed;
        top: 200px;
        right: 17px;
        z-index: 99;
    }

    .smodel {
        width: 500px;
        height: 147px;
        background: #D8D8D8;
        border-radius: 20px;
        position: relative;
        font-weight: 500;
        font-size: 37px;
        color: #5A5B5C;
        margin: 0 auto;
        margin-bottom: 72px;
        line-height: 1;
        padding: 82px 0 29px;
        text-align: center;

        &:last-of-type {
            margin-bottom: 0;
        }

        &::before {
            content: '';
            position: absolute;
            left: 50%;
            width: 121px;
            height: 121px;
            top: 0;
            transform: translate(-50%, -50%);
            background-size: 100% 100%;
        }

        &:nth-of-type(1)::before {
            background-image: url(https://img.chaolu.com.cn/ACT/annual-report-2024/a.png);
        }

        &:nth-of-type(2) {
            margin-bottom: 0;

            &::before {
                background-image: url(https://img.chaolu.com.cn/ACT/annual-report-2024/b.png);

            }
        }

        &.active {
            background: #FFECBA;
            color: #FF8820;
        }
    }

    .subline {
        display: flex;
        justify-content: center;
    }

    .subline-sub {
        width: 291px;
        height: 135px;
        margin: 57px 0 0;
        display: block;
    }

    .page1 {
        position: relative;

        .home {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
        }
    }

    .p1-con {
        position: relative;

        .p1-main {
            position: absolute;
            top: 181px;
            left: 0;
            right: 0;

            .p1-main-sub {
                position: absolute;
                bottom: -51px;
                left: 50%;
                width: 356px;
                height: 160px;
                transform: translateX(-50%);
                background: url(https://img.chaolu.com.cn/ACT/annual-report-2024/page1-sub.png);
                background-size: 100% 100%;
            }
        }
    }

    .p2-con {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 501px;
            background: url(https://img.chaolu.com.cn/ACT/annual-report-2024/page2-bottom.png);
            background-size: 100% 100%;
        }

        .p2-main {
            position: absolute;
            top: 225px;
            left: 0;
            right: 0;
            height: 1186px;
            background: url(https://img.chaolu.com.cn/ACT/annual-report-2024/page2-main.png);
            background-size: 100% 100%;
            padding-top: 402px;



            .p2-main-sub {
                width: 291px;
                height: 135px;
                margin: 57px auto 0;
                display: block;
            }
        }

    }

    .p3-con {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 501px;
            background: url(https://img.chaolu.com.cn/ACT/annual-report-2024/page3-bottom.png);
            background-size: 100% 100%;
        }

        .p3-main {
            position: absolute;
            top: 225px;
            left: 0;
            right: 0;
            height: 1186px;
            background: url(https://img.chaolu.com.cn/ACT/annual-report-2024/page3-main.png);
            background-size: 100% 100%;
            padding-top: 402px;


        }

    }

    .p4-con {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 501px;
            background: url(https://img.chaolu.com.cn/ACT/annual-report-2024/page4-bottom.png);
            background-size: 100% 100%;
        }

        .p4-main {
            position: absolute;
            top: 225px;
            left: 0;
            right: 0;
            height: 1186px;
            background: url(https://img.chaolu.com.cn/ACT/annual-report-2024/page4-main.png);
            background-size: 100% 100%;
            padding-top: 402px;


        }

    }

    .p5-con {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 501px;
            background: url(https://img.chaolu.com.cn/ACT/annual-report-2024/page5-bottom.png);
            background-size: 100% 100%;
        }

        .p5-main {
            position: absolute;
            top: 225px;
            left: 0;
            right: 0;
            height: 1186px;
            background: url(https://img.chaolu.com.cn/ACT/annual-report-2024/page5-main.png);
            background-size: 100% 100%;
            padding-top: 402px;


        }

    }

    .page6 {
        .final-sub {
            position: absolute;
            top: 1272px;
            left: 50%;
            width: 448px;
            transform: translateX(-50%);
        }
    }

    .data-swiper {
        height: 100vh;
    }

    .page-swiper,
    .page7 {
        position: relative;
        height: 100%;

        .pic-bg {
            height: 100%;
            object-fit: cover;
        }

        .info-block {
            position: absolute;
            line-height: 1;
            font-weight: 600;
            font-size: 27px;
            color: #444444;

            &.po1 {
                top: 307px;
                left: 93px;
            }

            &.po2 {
                top: 317px;
                left: 93px;
            }

            &.po3 {
                top: 251px;
                left: 67px;
            }

            &.po3-2 {
                top: 333px;
                left: 93px;
            }

            &.po4 {
                top: 235px;
                left: 93px;
            }

            .logo {
                width: 125px;
                height: 31px;
                margin-bottom: 28px;
            }

            .puhui {
                font-size: 41px;
                color: #D34100;
                font-weight: normal;
                margin: 0 4px;
            }

            .bl {
                margin-bottom: 19px;
            }

            .bmb {
                margin-bottom: 53px;
            }
        }

        .up {
            position: absolute;
            width: 38px;
            height: 47px;
            bottom: 240px;
            right: 67px;
        }

        .create {
            position: absolute;
            bottom: 5%;
            left: 50%;
            width: 448px;
            transform: translateX(-50%);
            background: url(https://img.chaolu.com.cn/ACT/annual-report-2024/submit.png);
            background-size: 100% 100%;
        }

        .nget {
            position: absolute;
            top: 1301px;
            left: 50%;
            width: 356px;
            transform: translateX(-50%);
        }
    }

    .page8 {
        position: relative;

        .clogo {
            width: 133px;
            position: absolute;
            left: 50%;
            top: 67px;
            margin-left: -67px;
        }

        .page8-top {
            position: absolute;
            top: 211px;
            left: 100px;
            right: 100px;
            line-height: 1;

            .top1 {
                display: flex;
                align-items: center;
                font-weight: bold;
                justify-content: center;
                color: #444444;
                margin-bottom: 16px;

                .userhead {
                    width: 73px;
                    height: 73px;
                    border-radius: 50%;
                    overflow: hidden;
                }

                .fh {
                    margin: 0 12px 0 21px;
                    font-size: 37px;
                }

                .username {
                    font-size: 32px;
                    white-space: nowrap;
                    max-width: 380px;
                }
            }

            .top2 {
                font-size: 29px;
                color: #4F4F4F;
                text-align: center;
            }
        }

        .page8-bottom {
            position: absolute;
            top: 1313px;
            left: 0;
            right: 0;
            line-height: 1;

            .bottom1 {
                display: flex;
                justify-content: center;

                .bm {
                    height: 44px;
                    background: #61952E;
                    border-radius: 22px;
                    padding: 0 21px 0;
                    font-weight: 600;
                    font-size: 20px;
                    color: #FFFFFF;

                    .bv {
                        margin: 0 9px;
                        font-weight: normal;
                        font-size: 33px;
                        position: relative;
                        top: 4px;
                    }

                    &:first-of-type {
                        margin-right: 20px;
                    }
                }
            }

            .bottom2 {
                font-weight: 500;
                font-size: 21px;
                color: #FFFFFF;
                text-align: center;
                margin: 16px 0 49px;
            }

            .bottom3 {
                display: flex;
                padding: 0 90px;
                justify-content: space-between;

                .bsub {
                    width: 254px;
                }

            }
        }
    }

    .canvascss {
        width: 750PX;
        height: 1685PX;

        .clogo {
            width: 133PX;
            position: absolute;
            left: 50%;
            top: 67PX;
            margin-left: -67PX;
        }

        .page8-top {
            position: absolute;
            top: 211PX;
            left: 100PX;
            right: 100PX;
            line-height: 1;

            .top1 {
                display: flex;
                align-items: center;
                font-weight: bold;
                justify-content: center;
                color: #444444;
                margin-bottom: 16PX;

                .userhead {
                    width: 73PX;
                    height: 73PX;
                    border-radius: 50%;
                    overflow: hidden;
                }

                .fh {
                    margin: 0 12PX 0 21PX;
                    font-size: 37PX;
                }

                .username {
                    font-size: 32PX;
                    white-space: nowrap;
                    max-width: 380PX;
                }
            }

            .top2 {
                font-size: 29PX;
                color: #4F4F4F;
                text-align: center;
            }
        }

        .page8-bottom {
            position: absolute;
            top: 1313PX;
            left: 0;
            right: 0;
            line-height: 1;

            .bottom1 {
                display: flex;
                justify-content: center;

                .bm {
                    height: 44PX;
                    background: #61952E;
                    border-radius: 22PX;
                    padding: 0 21PX 0;
                    font-weight: 600;
                    font-size: 20PX;
                    color: #FFFFFF;

                    .bv {
                        margin: 0 9PX;
                        font-weight: normal;
                        font-size: 33PX;
                        position: relative;
                        top: 4PX;
                    }

                    &:first-of-type {
                        margin-right: 20PX;
                    }
                }
            }

            .bottom2 {
                font-weight: 500;
                font-size: 21PX;
                color: #FFFFFF;
                text-align: center;
                margin: 16PX 0 49PX;
            }

            .bottom4 {
                text-align: center;
                line-height: 1;

                .ccode {
                    width: 120PX;
                    height: 120PX;
                }

                .bt1 {
                    font-weight: 600;
                    font-size: 24PX;
                    color: #333333;
                    margin: 21PX 0 12PX;
                }

                .bt2 {
                    font-weight: 500;
                    font-size: 20PX;
                    color: #333333;
                }
            }
        }
    }
}
</style>
